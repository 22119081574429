import {Component} from '@angular/core';

@Component({
  selector: 'du-lipo-landing',
  standalone: true,
  imports: [],
  templateUrl: './lipo-landing.component.html',
  styleUrl: './lipo-landing.component.scss'
})
export class LipoLandingComponent {

}
