import {Injectable} from '@angular/core';
import {Configuration} from "./models/configuration.model";
import {HttpClient} from "@angular/common/http";
import { map, Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private _configuration: Configuration = {} as Configuration;
  private _realmName?: string = undefined;

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  loadConfigs(): Observable<any> {
    return this.httpClient.get<Configuration>('/assets/config.json').pipe(
      map(c => this._configuration = c)
    )
  }

  get configuration(): Configuration {
    return this._configuration;
  }

  getRealm(): string {
    if (!this._realmName) {
      let split = location.pathname.split('/').filter(it => it.length > 0)
      let realm = localStorage.getItem('realm');

      if (split.length < 1 && !realm) {
        this._realmName = this.configuration.keycloak.realm
        this.router.navigate([this._realmName])
      } else if (!realm) {
        this._realmName = split[0]
        this.router.navigate(['/'])
      } else {
        this._realmName = realm
      }

      localStorage.setItem('realm', this._realmName)
    }

    return this._realmName!;
  }
}
