import {TenantTokenServiceModel} from "../services/models/tenant-token-service.model";
import {TenantTokenDetailModel} from "../components/models/tenant-token-detail.model";
import moment from 'moment';

export class TenantTokenServiceMapper {
  public static toTenantTokenDetailModel(tenantTokenServiceModel: TenantTokenServiceModel): TenantTokenDetailModel {
    return new TenantTokenDetailModel(
      tenantTokenServiceModel.name,
      tenantTokenServiceModel.token,
      tenantTokenServiceModel.validUntil ? moment(tenantTokenServiceModel.validUntil) : null,
      tenantTokenServiceModel.id,
    )
  }
}
