import {TenantDetailModel} from "../../../tenant/components/models/tenant-detail.model";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export function getSystemTenantsTable(tenants: TenantDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantDetailModel>(tenants)
  let selectionModel = new SelectionModel<TenantDetailModel>(true, [])
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'status', PropertyName: 'isActiveText'},
    {HeaderCellName: 'sapCompanyDB', PropertyName: 'sapCompanyDB'},
    {HeaderCellName: 'serviceName', PropertyName: 'serviceName'},
    {HeaderCellName: 'testSystem', PropertyName: 'testSystem'},
    {HeaderCellName: 'port', PropertyName: 'port'},
    {HeaderCellName: 'check_inbound', PropertyName: 'checkInbound'},
    {HeaderCellName: 'directory', PropertyName: 'directory'},
    {HeaderCellName: 'suspended', PropertyName: 'suspended'},
    {HeaderCellName: 'duifLicense', PropertyName: 'duifLicense'},
    {HeaderCellName: 'installNo', PropertyName: 'installNo'},
    {HeaderCellName: 'endpointUrl', PropertyName: 'endpointUrl'},
  );

  return new LipoTableModel<TenantDetailModel>(
    tableDataSource,
    selectionModel,
    displayedColumns,
    buttons,
    onRowClick
  );
}
