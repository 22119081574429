import {TenantUserMappingDetailModel} from "../components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingServiceModel} from "../services/models/tenant-user-mapping-service.model";

export class TenantUserMappingServiceMapper {
  public static toTenantUserMappingDetailModel(tenantUserMappingServiceModel: TenantUserMappingServiceModel): TenantUserMappingDetailModel {
    return new TenantUserMappingDetailModel(
      tenantUserMappingServiceModel.sapUserName,
      tenantUserMappingServiceModel.sapUserCode,
      tenantUserMappingServiceModel.userId,
      tenantUserMappingServiceModel.tenantId,
      tenantUserMappingServiceModel.id,
    )
  }
}
