export class LipoButton {
  constructor(
    public text: string,
    public onClick: () => Promise<any>,
    public className?: string,
  ) {
  }

  /**
   * Creates an array of LipoButton instances based on the provided button configurations.
   *
   * @param {...Object[]} buttons - An array of objects representing button configurations.
   * @param {string} buttons[].text - The text to be displayed on the button.
   * @param {Function} buttons[].onClick - The async function to be called when the button is clicked.
   * @param {string} [buttons[].className] - An optional class name to style the button.
   * @return {LipoButton[]} An array of LipoButton instances.
   */
  static build(...buttons: { text: string, onClick: () => Promise<any>, className?: string }[]): LipoButton[] {
    return buttons.map(item => new LipoButton(item.text, item.onClick, item.className));
  }
}
