<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">{{ 'password.reset' | translate }}</div>
  </div>

  <mat-dialog-content class="reset-password-dialog">
    <form [formGroup]="resetPasswordForm" class="form-column">
        <mat-form-field>
          <mat-label>{{ 'password.new' | translate }}</mat-label>
          <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" />
          <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility" [matTooltip]="'tooltip.togglePasswordVisibility' | translate">
            <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-error>
            @let newPasswordCtrl = resetPasswordForm.get('newPassword');
            @if(newPasswordCtrl?.hasError('required')) {
              {{ 'error.required' | translate }}
            } @else if (newPasswordCtrl?.hasError('pattern')) {
              {{ 'error.PasswordInvalid' | translate }}
            }
          </mat-error>
        </mat-form-field>


  <mat-form-field>
    <mat-label>{{ 'password.confirm' | translate }}</mat-label>
    <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="confirmPassword" />
    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility" [matTooltip]="'tooltip.togglePasswordVisibility' | translate">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
    <mat-error>
      @let confirmPasswordCtrl = resetPasswordForm.get('confirmPassword');
      @if(confirmPasswordCtrl?.hasError('required')) {
        {{ 'error.required' | translate }}
      } @else if (confirmPasswordCtrl?.hasError('pattern')) {
        {{ 'error.PasswordInvalid' | translate }}
      } @else if (confirmPasswordCtrl?.hasError('mismatch')){
        {{ 'error.PasswordMismatch' | translate }}
      }
    </mat-error>
  </mat-form-field>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
    <button mat-flat-button (click)="onSave()" [disabled]="resetPasswordForm.invalid">{{ 'button.submit' | translate }}</button>
  </mat-dialog-actions>
</div>
