import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {LipoRoutesDataModel} from "../../shared/models/lipo-routes-data.model";

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    // Get the roles required from the route.
    const routesDataModel = route.data as LipoRoutesDataModel;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(routesDataModel.roles) || routesDataModel.roles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return routesDataModel.roles.some(role => this.roles.includes(role));
  }
}
