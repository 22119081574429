import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {ProductOverviewModel} from "../components/models/product-overview.model";
import {
  ProductOverviewCardBodyComponent
} from "../components/product-overview-card-body/product-overview-card-body.component";

export class ProductCardMapper {
  public static toLipoCardModel(productOverviewModel: ProductOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: productOverviewModel.id,
      route: LipoRouteEnum.PRODUCT,
      title: productOverviewModel.name,
      body: {component: ProductOverviewCardBodyComponent, inputs: {productOverviewModel: productOverviewModel}},
      logoUrl: productOverviewModel.image
    });
  }
}
