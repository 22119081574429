import { Component, inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {CdkTrapFocus} from "@angular/cdk/a11y";
import {NgIf} from "@angular/common";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {
  LipoResetPasswordDialogComponent
} from "../../../shared/components/lipo-reset-password-dialog/lipo-reset-password-dialog.component";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'tenant-detail-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    MatIcon,
    CdkTrapFocus,
    NgIf,
    MatTooltip,
  ],
  templateUrl: './tenant-detail-dialog.component.html',
  styleUrls: ['./tenant-detail-dialog.component.scss'],
})
export class TenantDetailDialogComponent implements OnInit {
  tenantForm!: FormGroup;
  showPassword: boolean = false;
  isPrefixedPassword: boolean = false;
  isPresentPassword: boolean = false;
  data = inject(MAT_DIALOG_DATA);
  private readonly encryptPrefix = 'encrypt_';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TenantDetailDialogComponent>,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    const initialPassword = this.data.sapPassword;
    this.isPrefixedPassword = initialPassword.startsWith(this.encryptPrefix);
    this.isPresentPassword = !!initialPassword;

    this.tenantForm = this.fb.group({
      sapUser: [this.data.sapUser, [
        Validators.required,
        Validators.pattern(RegexPatterns.Name)
      ]],
      sapPassword: [this.data.sapPassword, [
        Validators.required,
        Validators.pattern(RegexPatterns.Password)
      ]]
    });
    if (this.isPrefixedPassword) {
      this.tenantForm.get('sapPassword')?.disable();
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }


  onSave(): void {
    const sapPasswordControl = this.tenantForm.get('sapPassword');

    if (sapPasswordControl && sapPasswordControl.value && !sapPasswordControl.value.startsWith(this.encryptPrefix)) {
      sapPasswordControl.setValue(`${this.encryptPrefix}${sapPasswordControl.value}`);
    }

    if (sapPasswordControl?.disabled) {
      sapPasswordControl.enable();
    }

    if (this.tenantForm.valid) {
      this.dialogRef.close(this.tenantForm.value);
    }

    if (this.isPrefixedPassword) {
      sapPasswordControl?.disable();
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  openResetPasswordDialog(): void {
    const dialogRef = this.dialog.open(LipoResetPasswordDialogComponent, {
      width: '400px',
      data: { currentPassword: this.tenantForm.get('sapPassword')?.value }
    });

    dialogRef.afterClosed().subscribe(newPassword => {
      if (newPassword) {
        this.tenantForm.get('sapPassword')?.setValue(newPassword);
      }
    });
  }

  showResetButton(): boolean {
    return this.isPresentPassword;
  }
}
