<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div mat-dialog-title class="dialog-header">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
    <div class="dialog-title">{{ 'dialog_required' | translate }}</div>
  </div>

  <mat-dialog-content class="tenant-detail-dialog">
    <form [formGroup]="tenantForm" class="form-column">
      <mat-form-field>
        <mat-label>{{ 'sapUser' | translate }}</mat-label>
        <input matInput formControlName="sapUser" />
        <mat-error *ngIf="tenantForm.get('sapUser')?.hasError('required')">
          {{ 'error.required' | translate }}
        </mat-error>
        <mat-error *ngIf="tenantForm.get('sapUser')?.hasError('pattern')">
          {{ 'error.NameInvalid' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'sapPassword' | translate }}</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="sapPassword" [readonly]="isPrefixedPassword"/>
        <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility" [matTooltip]="'tooltip.togglePasswordVisibility' | translate" [hidden]="isPrefixedPassword">
          <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
          <button mat-icon-button matSuffix (click)="openResetPasswordDialog()" type="button" aria-label="Reset password" [matTooltip]="'tooltip.resetPassword' | translate" [hidden]="!showResetButton()">
              <mat-icon>lock_reset</mat-icon>
          </button>
          <mat-error>
              @let sapPasswordCtrl = tenantForm.get('sapPassword');
              @if(sapPasswordCtrl?.hasError('required')) {
                  {{ 'error.required' | translate }}
              } @else if (sapPasswordCtrl?.hasError('pattern')) {
                  {{ 'error.PasswordInvalid' | translate }}
              }
          </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
    <button mat-flat-button (click)="onSave()" [disabled]="tenantForm.invalid">{{ 'button.submit' | translate }}</button>
  </mat-dialog-actions>
</div>
