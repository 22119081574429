import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class TenantUserMappingDetailModel implements LipoModelInterface {
  constructor(
    public sapUserName: string = '',
    public sapUserCode: string = '',
    public userId: number | null = null,
    public tenantId: number | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): string | number | null {
    return this.id;
  }
}
