import moment from 'moment';
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoCardModel} from "../../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LicenseOverviewCardBodyComponent} from "../license-overview-card-body/license-overview-card-body.component";

export class LicenseOverviewModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public productName: string,
    public customerName: string,
    public purchaseDate: moment.Moment,
    public expiryDate: moment.Moment,
    public logo: string | null = null,
    public isActive: boolean = false,
    public isActiveText: 'active' | 'idle' = "idle",
  ) {
  }

  toLipoCardModel(): LipoCardModel {
    return new LipoCardModel({
      id: this.id,
      route: LipoRouteEnum.LICENCE,
      title: this.productName,
      subtitle: this.customerName,
      body: {component: LicenseOverviewCardBodyComponent, inputs: {licenseOverviewModel: this}},
      logoUrl: this.logo,
    });
  }

  getId(): number | null {
    return this.id;
  }
}
