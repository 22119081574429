import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerDataService} from "../../services/customer-data.service";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {CustomerDetailModel} from "../models/customer-detail.model";
import {CustomerServiceMapper} from "../../mappers/customer-service.mapper";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {forkJoin, map, Observable, of} from "rxjs";
import {CustomerDetailMapper} from "../../mappers/customer-detail.mapper";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {FormControl, Validators} from "@angular/forms";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormMail,
  LipoFormPhone,
  LipoFormSelect,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {SystemDetailModel} from "../../../system/components/models/system-detail.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {KeycloakService} from "keycloak-angular";
import {KeycloakRoleEnum} from "../../../shared/enums/keycloak-role.enum";
import {PartnerDataService} from "../../../partner/services/partner-data.service";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {v4 as uuidv4} from "uuid";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {UserDetailModel} from "../../../user/components/models/user-detail.model";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {TranslateService} from "@ngx-translate/core";
import {ProgressService} from "../../../shared/services/progress.service";

@Component({
  selector: 'du-customer-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './customer-detail.component.html',
  styleUrl: './customer-detail.component.scss'
})
export class CustomerDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  customerId: number | null = null;

  private readonly _SystemTableUuid: string = uuidv4();
  private readonly _UserTableUuid: string = uuidv4();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _customerService: CustomerDataService,
    private _snackBar: SnackbarService,
    private _partnerService: PartnerDataService,
    private _keycloak: KeycloakService,
    private _translate: TranslateService,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptyCustomer();
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('customerId');
              if (!idParam) return;

              let id = +idParam;

              this.customerId = id
              this.loadCustomer(+id);
            }
          })
        }
      }
    });
  }

  loadCustomer(id: number): void {
    this._customerService.getCustomer(id).subscribe({
      next: value => {
        let customerDetailModel = CustomerServiceMapper.toCustomerDetailModel(value)
        this.createDetail(customerDetailModel);
      }
    })
  }

  createEmptyCustomer(): void {
    this._partnerService.getPartners().subscribe(partners => {
      const emptyCustomerModel = new CustomerDetailModel();
      emptyCustomerModel.partner = partners[0]?.id;
      this.createDetail(emptyCustomerModel);
    });
  }

  createDetail(customerModel: CustomerDetailModel): void {
    let adminForm$ = this._keycloak.isUserInRole(KeycloakRoleEnum.ADMIN) ? this.getAdminFormFields(customerModel) : of([]);

    forkJoin({
      adminForm: adminForm$,
      baseForm: this.getFormFields(customerModel),
    }).subscribe(({adminForm, baseForm}) => {
      let baseForms = [...baseForm, ...adminForm] as LipoFormControlModel[];

      let tabs = this.getTabs(customerModel);
      let lipoFormModel = new LipoFormModel(baseForms);
      this.detailModel = CustomerDetailMapper.toLipoDetailModel(customerModel, lipoFormModel, tabs);

      this._progressService.stopLoading()
    });
  }

  getFormFields(model: CustomerDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
          value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
          key: 'name',
          label: 'name',
          order: 1,
        }
      ),
      new LipoFormPhone({
          value: new FormControl(model.phone, [Validators.pattern(RegexPatterns.Phone)]),
          key: 'phone',
          label: 'phone',
          hint: 'hint.phone',
          order: 2,
        }
      ),
      new LipoFormMail({
        value: new FormControl(model.mail, [Validators.required, Validators.email, Validators.pattern(RegexPatterns.Email)]),
        key: 'mail',
        label: 'mail',
        order: 3,
      }),
      new LipoFormCheckbox({
        value: new FormControl(model.createUsersInKeyCloak),
        key: 'createUsersInKeyCloak',
        label: 'create_users_in_keycloak',
        order: 4,
      }),
    ];
    return of(fields);
  }

  getAdminFormFields(model: CustomerDetailModel): Observable<LipoFormControlModel[]> {
    return this._partnerService.getPartners().pipe(
      map(partners => [
        new LipoFormSelect({
          value: new FormControl(partners.find(partner => partner.id === model.partner), Validators.required),
          key: 'partner',
          label: 'partner',
          settings: {
            options: partners.map(partner => ({key: partner.name, value: partner})),
          },
        })
      ])
    );
  }

  getTabs(model: CustomerDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'systems',
        components: [
          {
            uuid: this._SystemTableUuid,
            component: this.getSystemTable(model.systems)
          }
        ]
      },
      {
        title: 'users',
        components: [
          {
            uuid: this._UserTableUuid,
            component: this.getUserTable(model.users)
          }
        ]
      }
    );
  }

  getUserTable(users: UserDetailModel[]) {
    let tableDataSource = new MatTableDataSource<UserDetailModel>(users);
    let selectionModel = new SelectionModel<UserDetailModel>(true, []);
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: 'firstName', PropertyName: 'firstName'},
      {HeaderCellName: 'lastName', PropertyName: 'lastName'},
      {HeaderCellName: 'mail', PropertyName: 'email'},
      {HeaderCellName: 'sapUser', PropertyName: 'sapUser'},
      {HeaderCellName: 'keycloak.userId', PropertyName: 'keyCloakUserId'},
    );
    let buttons = this.getUserTableButtons();

    return new LipoTableModel<UserDetailModel>(
      tableDataSource,
      selectionModel,
      displayedColumns,
      buttons,
      (value) => this.onUserRowClick(value)
    );
  }

  private getUserTableButtons(): LipoButton[] {
    if (!this.customerId) return [];

    return LipoButton.build(
      {
        text: "button.user.add",
        onClick: async () => await this._router.navigate([LipoRouteEnum.CUSTOMER, this.customerId, LipoRouteEnum.USER, 'new'])
      },
      {
        text: "button.user.sync",
        onClick: async () => {
          if (this.customerId) {
            let id = this.customerId

            this._customerService.syncUserToKeyCloak(id).subscribe({
              next: () => {
                this.loadCustomer(id);
                this._snackBar.Saved();
              }
            });
          }
        }
      }
    );
  }

  getSystemTable(systems: SystemDetailModel[]) {
    let tableDataSource = new MatTableDataSource<SystemDetailModel>(systems)
    let selectionModel = new SelectionModel<SystemDetailModel>(true, [])
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: 'id', PropertyName: 'sapSystemId'},
      {HeaderCellName: 'name', PropertyName: 'name'},
      {HeaderCellName: 'hardware_key', PropertyName: 'hardwareKey'},
      {HeaderCellName: 'initial_date', PropertyName: 'initialDate'},
      {HeaderCellName: 'status', PropertyName: 'isActiveText'},
    );
    let buttons = this.getAddSystemButton();

    return new LipoTableModel<SystemDetailModel>(
      tableDataSource,
      selectionModel,
      displayedColumns,
      buttons,
      (value) => this.onSystemRowClick(value)
    );
  }

  private getAddSystemButton(): LipoButton[] {
    if(!this.customerId) return [];

    return LipoButton.build({
      text: "button.system.add",
      onClick: async () => {
        await this._router.navigate([LipoRouteEnum.SYSTEM, 'new'], {
          queryParams: { customerId: this.customerId }
        });
      }
    });
  }

  async onSystemRowClick(value: LipoModelInterface) {
    let id = value.getId();

    if (id) {
      await this._router.navigate([LipoRouteEnum.SYSTEM, id]);
    }
  }

  async onUserRowClick(value: LipoModelInterface) {
    let id = value.getId();

    if (id) {
      await this._router.navigate([LipoRouteEnum.CUSTOMER, this.customerId, LipoRouteEnum.USER, id]);
    }
  }

  onDeleteClicked(id: number | null): void {
    if (id === null) return;

    this._customerService.deleteCustomer(id).subscribe({
      next: (successfully) => {
        if (successfully) {
          this._router.navigate([LipoRouteEnum.CUSTOMER]).then(() => this._snackBar.Deleted());
        }
      }
    })
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | null): void {
    if (!detailModel.form?.formGroup) return;

    let systemTab = detailModel.findComponent<LipoTableModel<SystemDetailModel>>(this._SystemTableUuid)
    let userTab = detailModel.findComponent<LipoTableModel<UserDetailModel>>(this._UserTableUuid)
    let serviceCustomerModel = LipoFormMapper.toCustomerServiceModel(detailModel.form?.formGroup, null, undefined, systemTab?.tableDataSource.data, userTab?.tableDataSource.data);

    if (id !== null && id > 0) {
      serviceCustomerModel.id = id;
      this._customerService.updateCustomer(serviceCustomerModel).subscribe({
        next: customer => {
          this.createDetail(CustomerServiceMapper.toCustomerDetailModel(customer));
          this._snackBar.Saved();
        }
      });
    } else {
      this._customerService.createCustomer(serviceCustomerModel).subscribe({
        next: customer => {
          this._router.navigate([LipoRouteEnum.CUSTOMER, customer.id]).then(() => this._snackBar.Saved());
        }
      });
    }
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
