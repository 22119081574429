import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {LipoRouteEnum} from "../shared/enums/lipo-route.enum";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {KeycloakProfile} from "keycloak-js";
import {KeycloakService} from "keycloak-angular";
import {KeycloakRoleEnum} from "../shared/enums/keycloak-role.enum";

@Component({
  selector: 'du-sidenav',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    NgOptimizedImage,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    TranslateModule,
    RouterLinkActive,
    NgForOf,
    NgIf
  ],
})
export class SidenavComponent implements OnInit {
  @ViewChild('drawer') sidenav!: MatSidenav;

  readonly languages = [
    { value: 'en', label: 'English', img: 'assets/gb.svg' },
    { value: 'de', label: 'Deutsch', img: 'assets/de.svg'},
  ];

  public userProfile: KeycloakProfile | null = null;
  public userProfilePicture: string = 'assets/images/user_profile_placeholder.png';

  currentLanguage: any = {};

  private readonly localStorageProperty: string;
  protected isAdmin: boolean = false;
  protected isPartner: boolean = false;

  constructor(
    protected readonly _keycloak: KeycloakService,
    protected _translate: TranslateService,
    private _router: Router,
  ) {
    this.localStorageProperty = 'language';
  }

  public async ngOnInit() {
    await this.loadUserProfile();
    let currentLanguage = this.manageLocalStorageLanguage();
    this.setDropDownInitialLanguage(currentLanguage);
    const roles = this._keycloak.getUserRoles()
    this.isAdmin = roles.includes(KeycloakRoleEnum.ADMIN)
    this.isPartner = roles.includes(KeycloakRoleEnum.PARTNER)
  }

  async loadUserProfile() {
    if (this._keycloak.isLoggedIn()) {
      this.userProfile = await this._keycloak.loadUserProfile();
    } else {
      this.userProfile = null;
    }
  }

  async login() {
    await this._keycloak.login();
  }

  async logout() {
    await this._keycloak.logout();
  }

  manageLocalStorageLanguage(): string {
    let localStorageLang = localStorage.getItem(this.localStorageProperty);

    if (localStorageLang === null) {
      localStorageLang = this.getBrowserLanguage();
      localStorage.setItem(this.localStorageProperty, localStorageLang)
    }

    this._translate.use(localStorageLang);

    return this._translate.currentLang;
  }

  getBrowserLanguage() {
    let userLang = navigator.language.split('-')[0];
    if (this.languages.map(lang => lang.value).includes(userLang)) {
      return userLang;
    }
    return this._translate.getDefaultLang();
  }

  setNewLanguage(language: string) {
    this._translate.use(language);
    localStorage.setItem(this.localStorageProperty, language);
    this.setDropDownInitialLanguage(language);
  }

  setDropDownInitialLanguage(language: string) {
    this.currentLanguage = this.languages.find(lang => lang.value === language);
  }

  async navigateHome() {
    await this._router.navigate([LipoRouteEnum.HOME])
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
