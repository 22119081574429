import {PartnerDetailModel} from "../../partner/components/models/partner-detail.model";
import {FormGroup} from "@angular/forms";
import {Address} from "../models/lipo-address.model";
import {CustomerDetailModel} from "../../customer/components/models/customer-detail.model";
import {SystemDetailModel, SystemSaveDetailModel} from "../../system/components/models/system-detail.model";
import {CustomerDetailMapper} from "../../customer/mappers/customer-detail.mapper";
import {PartnerDetailMapper} from "../../partner/mappers/partner-detail.mapper";
import {PartnerServiceModel} from "../../partner/services/models/partner-service.model";
import {CustomerServiceModel} from "../../customer/services/models/customer-service.model";
import {SystemSaveServiceModel, SystemServiceModel} from "../../system/services/models/system-service.model";
import {SystemSaveDetailMapper} from "../../system/mappers/system-save-detail.mapper";
import {TenantServiceModel} from "../../tenant/services/models/tenant-service.model";
import {TenantDetailModel} from "../../tenant/components/models/tenant-detail.model";
import {TenantDetailMapper} from "../../tenant/mappers/tenant-detail.mapper";
import {SystemDetailMapper} from "../../system/mappers/system-detail.mapper";
import {ProductServiceModel} from "../../product/services/models/product-service.model";
import {ProductDetailMapper} from "../../product/mappers/product-detail.mapper";
import {ProductDetailModel} from "../../product/components/models/product-detail.model";
import {ProductPriceDetailModel} from "../../productPrice/components/models/product-price-detail.model";
import {ProductPriceServiceModel} from "../../productPrice/services/models/product-price-service.model";
import {ProductPriceDetailMapper} from "../../productPrice/mappers/product-price-detail.mapper";
import {UserServiceModel, UserServiceSaveModel} from "../../user/services/models/user-service.model";
import {UserDetailModel, UserSaveDetailModel} from "../../user/components/models/user-detail.model";
import {UserSaveDetailMapper} from "../../user/mappers/user-save-detail.mapper";
import {TenantTokenServiceSaveModel} from "../../tenant/services/models/tenant-token-service.model";
import {TenantTokenDetailSaveModel} from "../../tenant/components/models/tenant-token-detail.model";
import {TenantTokenSaveDetailMapper} from "../../tenant/mappers/tenant-token-detail.mapper";
import {TenantUserMappingServiceModel} from "../../tenant/services/models/tenant-user-mapping-service.model";
import {TenantUserMappingDetailModel} from "../../tenant/components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingDetailMapper} from "../../tenant/mappers/tenant-user-mapping-detail.mapper";
import {TenantAppSettingsServiceModel} from "../../tenant/services/models/tenant-app-settings-service.model";
import {TenantAppSettingsDetailModel} from "../../tenant/components/models/tenant-app-settings-detail.model";
import {TenantAppSettingsDetailMapper} from "../../tenant/mappers/tenant-app-settings-detail.mapper";
import {UserDetailMapper} from "../../user/mappers/user-detail.mapper";
import moment from "moment";

export class LipoFormMapper {
  public static toPartnerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], customers: CustomerDetailModel[] = []): PartnerServiceModel {
    let fieldValue = formGroup.getRawValue() as PartnerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.customers = customers;

    return PartnerDetailMapper.toPartnerServiceModel(fieldValue);
  }

  public static toCustomerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], systems: SystemDetailModel[] = [], users: UserDetailModel[] = []): CustomerServiceModel {
    let fieldValue = formGroup.getRawValue() as CustomerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.systems = systems;
    fieldValue.users = users;

    return CustomerDetailMapper.toCustomerServiceModel(fieldValue);
  }

  public static toSystemSaveServiceModel(formGroup: FormGroup): SystemSaveServiceModel {
    let fieldValue = formGroup.getRawValue() as SystemSaveDetailModel;

    return SystemSaveDetailMapper.toSystemSaveServiceModel(fieldValue)
  }

  public static toSystemServiceModel(formGroup: FormGroup, tenantDetails: TenantDetailModel[] = []): SystemServiceModel {
    let fieldValue = formGroup.getRawValue() as SystemDetailModel;
    fieldValue.tenants = tenantDetails;

    return SystemDetailMapper.toSystemServiceModel(fieldValue)
  }
  public static toProductServiceModel(formGroup: FormGroup, productPrice: ProductPriceDetailModel[] = []): ProductServiceModel {
    let fieldValue = formGroup.getRawValue() as ProductDetailModel;
    fieldValue.productPrice = productPrice

    return ProductDetailMapper.toProductServiceModel(fieldValue);
  }

  public static toProductPriceSaveModel(formGroup: FormGroup): ProductPriceServiceModel {
    let fieldValue = formGroup.getRawValue() as ProductPriceDetailModel

    return ProductPriceDetailMapper.toProductPriceServiceModel(fieldValue)
  }

  public static toTenantSaveModel(formGroup: FormGroup, old?: TenantDetailModel): TenantServiceModel {
    let fieldValue = formGroup.getRawValue();
    let sap = fieldValue['sap'];
    let artifact = fieldValue['artifact'];
    let gitlab = fieldValue['gitlab'];
    let technical = fieldValue['technical'];
    let checkBoxes = fieldValue['checkBoxes'];
    let tenant = new TenantDetailModel(
      checkBoxes.active,
      null,
      artifact.artifactDate,
      checkBoxes.checkInbound,
      artifact.directory,
      gitlab.gitlabJobName,
      gitlab.gitlabProjectId,
      gitlab.gitlabRefName,
      technical.port,
      sap.sapCompanyDB,
      sap.sapPassword,
      sap.sapUser,
      technical.serviceName,
      checkBoxes.testSystem,
      checkBoxes.suspended,
      technical.duifLicense,
      technical.installNo,
      technical.endpointUrl,
      old?.allowWithoutAuth,
      technical.proxyName,
      old?.appLicenses,
      old?.tokens,
      old?.appSettings,
      old?.tenantUserMappings,
      old?.id
    )

    return TenantDetailMapper.toTenantServiceModel(tenant);
  }

  public static toTenantUserMappingServiceModel(formGroup: FormGroup, tenantUserMappingId: number | null): TenantUserMappingServiceModel {
    let fieldValue = formGroup.getRawValue() as TenantUserMappingDetailModel;
    fieldValue.sapUserCode = fieldValue.sapUserCode.toString() ;
    fieldValue.id = tenantUserMappingId;

    return TenantUserMappingDetailMapper.toTenantUserMappingServiceModel(fieldValue);
  }

  public static toTenantTokenSaveServiceModel(formGroup: FormGroup): TenantTokenServiceSaveModel{
    let fieldValue = formGroup.getRawValue() as TenantTokenDetailSaveModel;

    let validUntilControl = formGroup.get('validUntil');

    fieldValue.validUntil = validUntilControl?.value
      ? moment(validUntilControl.value, 'DD.MM.YYYY')
      : moment('31.12.2099', 'DD.MM.YYYY');

    return TenantTokenSaveDetailMapper.toTenantTokenServiceModel(fieldValue);
  }

  public static toUserSaveServiceModel(formGroup: FormGroup): UserServiceSaveModel {
    let fieldValue = formGroup.getRawValue() as UserSaveDetailModel;

    return UserSaveDetailMapper.toUserSaveServiceModel(fieldValue);
  }

  public static toUserServiceModel(formGroup: FormGroup): UserServiceModel {
    let fieldValue = formGroup.getRawValue() as UserDetailModel;

    return UserDetailMapper.toUserServiceModel(fieldValue);
  }

  public static toTenantAppSettingsServiceModel(formGroup: FormGroup, tenantAppSettingsId: number | null): TenantAppSettingsServiceModel {
    const appSettingsFormGroupKey = 'appSettings';

    let appSettings = formGroup.get(appSettingsFormGroupKey)?.getRawValue() as TenantAppSettingsDetailModel;

    appSettings.id = tenantAppSettingsId

    let allFormGroups = this.getAllFormGroupsExcept(formGroup, appSettingsFormGroupKey);

    if (allFormGroups.length === 1) {
      appSettings.attributes = this.toStrDict(this.getAllFormGroupsExcept(formGroup, appSettingsFormGroupKey)[0].getRawValue())

    }

    return TenantAppSettingsDetailMapper.toTenantAppSettingsServiceModel(appSettings);
  }

  private static toStrDict(obj: any): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(obj)) {
      result[String(key)] = String(value);
    }
    return result;
  }

  private static getAllFormGroupsExcept(formGroup: FormGroup, excludedKey: string): FormGroup[] {
    const formGroups: FormGroup[] = [];

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup && key !== excludedKey) {
        formGroups.push(control as FormGroup);
      }
    });

    return formGroups;
  }

}
