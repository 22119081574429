import {FormControl, FormGroup} from "@angular/forms";
import {LipoFormControlModel} from "../components/models/lipo-form-control.model";
import {LipoFormGroupModel} from "../components/models/lipo-form-group.model";

export class LipoFormModel {
  formGroup: FormGroup;

  constructor(
    public baseForms: (LipoFormControlModel | LipoFormGroupModel)[],
    public style: string = '',
  ) {
    this.formGroup = this.toFormGroup(baseForms);
  }

  private toFormGroup(baseForms: (LipoFormControlModel | LipoFormGroupModel)[]): FormGroup {
    const group: any = {};
    baseForms.sort((a, b) => a.order - b.order);
    baseForms.forEach(baseForm => {
      if (baseForm instanceof LipoFormGroupModel) {
        group[baseForm.key] = this.toFormGroup(baseForm.controls);
      } else {
        group[baseForm.key] = baseForm.value;
      }
    });

    return new FormGroup(group);
  }

  /**
   * Adds an array of form controls or form groups to the current form group instance.
   *
   * @param {Array<LipoFormControlModel | LipoFormGroupModel>} baseForms - An array containing instances of LipoFormControlModel or LipoFormGroupModel.
   * Each item in the array is added to the form group, either as a form control or form group, based on its type.
   */
  addControlsOrGroups(baseForms: (LipoFormControlModel | LipoFormGroupModel)[]): void {
    baseForms.forEach(baseForm => {
      if (baseForm instanceof LipoFormGroupModel) {
        this.formGroup.addControl(baseForm.key, this.toFormGroup(baseForm.controls));
      } else {
        this.formGroup.addControl(baseForm.key, new FormControl(baseForm.value));
      }

      this.baseForms.push(baseForm);
    });
  }

  /**
   * Removes a control or a group from the form based on the provided key.
   *
   * @param {string} key - The key of the control or group to be removed.
   */
  removeControlOrGroup(key: string): void {
    if (this.formGroup.contains(key)) {
      this.formGroup.removeControl(key);
    }

    this.baseForms = this.baseForms.filter(baseForm => baseForm.key !== key);
    this.formGroup = this.toFormGroup(this.baseForms);
  }
}
