import {TenantUserMappingDetailModel} from "../components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingServiceModel} from "../services/models/tenant-user-mapping-service.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";

export class TenantUserMappingDetailMapper {
  public static toTenantUserMappingServiceModel(tenantUserMappingDetailModel: TenantUserMappingDetailModel): TenantUserMappingServiceModel {
    return {
      sapUserName: tenantUserMappingDetailModel.sapUserName,
      sapUserCode: tenantUserMappingDetailModel.sapUserCode,
      userId: tenantUserMappingDetailModel.userId!!,
      tenantId: tenantUserMappingDetailModel.tenantId!!,
      id: tenantUserMappingDetailModel.id,
    }
  }

  public static toLipoDetailModel(tenantUserMappingDetailModel: TenantUserMappingDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      tenantUserMappingDetailModel.id ? tenantUserMappingDetailModel.sapUserName : 'user_mappings',
      tenantUserMappingDetailModel.sapUserCode ? `#${tenantUserMappingDetailModel.sapUserCode}` : null,
      null,
      formModel,
      tabs
    )
  }
}
