import {LipoRouteEnum} from "../enums/lipo-route.enum";
import {Type} from "@angular/core";

/**
 * Options for constructing a LipoCardModel.
 */
interface LipoCardModelOptions {
  /** Unique identifier for the card. */
  id: number | null;
  /** Route associated with the card. */
  route: LipoRouteEnum;
  /** Main title of the card. */
  title: string;
  /** Subtitle of the card. Can be null. */
  subtitle?: string | null;
  /**
   * Body content of the card.
   * Angular component.
   */
  body?: {
    component: Type<any>,
    inputs: Record<string, unknown>
  } | null;
  /** URL for the logo image. Can be null. */
  logoUrl?: string | null;
  /** Number of licences associated with the card. Can be null. */
  licencesAmount?: number | null;
  /** Number of customers associated with the card. Can be null. */
  customersAmount?: number | null;
  /** Number of systems associated with the card. Can be null. */
  systemsAmount?: number | null;
  /** Number of products associated with the card. Can be null. */
  productsAmount?: number | null;
  /** Number of tenants associated with the card. Can be null. */
  tenantsAmount?: number | null;
  /** Number of users associated with the card. Can be null. */
  usersAmount?: number | null;
}

export class LipoCardModel {
  public id: number | null;
  public route: LipoRouteEnum;
  public title: string;
  public subtitle: string | null;
  public body: {
    component: Type<any>,
    inputs: { [key: string]: any }
  } | null;
  public logoUrl: string | null;
  public licencesAmount: number | null;
  public customersAmount: number | null;
  public systemsAmount: number | null;
  public productsAmount: number | null;
  public tenantsAmount: number | null;
  public usersAmount: number | null;
  [key: string]: any;

  constructor(options: LipoCardModelOptions) {
    this.id = options.id;
    this.route = options.route;
    this.title = options.title;
    this.subtitle = options.subtitle ?? null;
    this.body = options.body ?? null;
    this.logoUrl = options.logoUrl ?? null;
    this.licencesAmount = options.licencesAmount ?? null;
    this.customersAmount = options.customersAmount ?? null;
    this.systemsAmount = options.systemsAmount ?? null;
    this.productsAmount = options.productsAmount ?? null;
    this.tenantsAmount = options.tenantsAmount ?? null;
    this.usersAmount = options.usersAmount ?? null;
  }
}
