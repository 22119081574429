import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {map, Observable} from "rxjs";
import {TenantUserMappingServiceModel} from "./models/tenant-user-mapping-service.model";
import {TenantServiceModel} from "./models/tenant-service.model";

@Injectable({
  providedIn: 'root'
})
export class TenantUserMappingDataService {
  private readonly tenantEndpoint: string;
  private readonly systemEndpoint: string;
  private readonly userMappingEndpoint: string;
  private readonly api: string;

  constructor(
    private _http: HttpClient,
    _config: ConfigurationService
  ) {
    let config = _config.configuration;
    this.tenantEndpoint = config.api.endpoints.tenant;
    this.systemEndpoint = config.api.endpoints.system;
    this.userMappingEndpoint = config.api.endpoints.userMapping;
    this.api = config.api.backendUrl;
  }

  createTenantUserMapping(systemId: number, tenantId: number, tenantUserMapping: TenantUserMappingServiceModel): Observable<TenantUserMappingServiceModel> {
    return this._http.post<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.userMappingEndpoint}`, tenantUserMapping).pipe(
      map((tenantServiceModel: TenantServiceModel) => this.getTenantUserMappingByTenantUserMapping(tenantServiceModel, tenantUserMapping))
    );
  }

  getTenantUserMapping(systemId: number, tenantId: number, tenantUserMappingId: number): Observable<TenantUserMappingServiceModel> {
    return this._http.get<TenantUserMappingServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.userMappingEndpoint}/${tenantUserMappingId}`);
  }

  deleteTenantUserMapping(systemId: number, tenantId: number, tenantUserMappingId: number): Observable<TenantServiceModel> {
    return this._http.delete<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.userMappingEndpoint}/${tenantUserMappingId}`);
  }

  updateTenantUserMapping(systemId: number, tenantId: number, tenantUserMapping: TenantUserMappingServiceModel): Observable<TenantUserMappingServiceModel> {
    return this._http.put<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.userMappingEndpoint}/${tenantUserMapping.id}`, tenantUserMapping).pipe(
      map((tenantServiceModel: TenantServiceModel) => this.getTenantUserMappingById(tenantServiceModel, tenantUserMapping.id))
    );
  }

  private getTenantUserMappingByTenantUserMapping(tenantServiceModel: TenantServiceModel, tenantUserMapping: TenantUserMappingServiceModel): TenantUserMappingServiceModel {
    const updatedTenant = tenantServiceModel.tenantUserMappings.find(
      tenant =>
        (tenant.sapUserName === tenantUserMapping.sapUserName) &&
        (tenant.sapUserCode === tenantUserMapping.sapUserCode) &&
        (tenant.userId === tenantUserMapping.userId) &&
        (tenant.tenantId === tenantUserMapping.tenantId)
      );
    return updatedTenant || {} as TenantUserMappingServiceModel;
  }

  private getTenantUserMappingById(tenantServiceModel: TenantServiceModel, tenantUserMappingId: number | null): TenantUserMappingServiceModel {
    return tenantServiceModel.tenantUserMappings.find(tenantUserMapping => tenantUserMapping.id === tenantUserMappingId) || {} as TenantUserMappingServiceModel;
  }
}
