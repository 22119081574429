import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material/table";
import {TenantTokenDetailModel} from "../models/tenant-token-detail.model";
import {DatePipe} from "@angular/common";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";

export function getTenantUserMappingsTable(userMapping: TenantUserMappingDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantUserMappingDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantUserMappingDetailModel>(userMapping)
  let selectionModel = new SelectionModel<TenantUserMappingDetailModel>(true, [])
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'sapUserName', PropertyName: 'sapUserName'},
    {HeaderCellName: 'sapUserCode', PropertyName: 'sapUserCode'},
    {HeaderCellName: 'userId', PropertyName: 'userId'},
  );

  return new LipoTableModel<TenantUserMappingDetailModel>(
    tableDataSource,
    selectionModel,
    displayedColumns,
    buttons,
    onRowClick
  );
}

export function getTenantTokenTable(tenantToken: TenantTokenDetailModel[], datePipe: DatePipe, buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantTokenDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantTokenDetailModel>(tenantToken)
  let selectionModel = new SelectionModel<TenantTokenDetailModel>(true, [])
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'name', PropertyName: 'name'},
    {HeaderCellName: 'api_token', PropertyName: 'token'},
    {HeaderCellName: 'valid.to', PropertyName: 'validUntil', Pipes: [datePipe]},
  );

  return new LipoTableModel<TenantTokenDetailModel>(
    tableDataSource,
    selectionModel,
    displayedColumns,
    buttons,
    onRowClick
  );
}

export function getAppSettingsTable(tenantAppSettings: TenantAppSettingsDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantAppSettingsDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantAppSettingsDetailModel>(tenantAppSettings)
  let selectionModel = new SelectionModel<TenantAppSettingsDetailModel>(true, [])
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'app_name', PropertyName: 'appName'},
    {HeaderCellName: 'description', PropertyName: 'description'},
  );

  return new LipoTableModel<TenantAppSettingsDetailModel>(
    tableDataSource,
    selectionModel,
    displayedColumns,
    buttons,
    onRowClick
  );
}
