import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {map, Observable} from "rxjs";
import {TenantServiceModel} from "./models/tenant-service.model";
import {TenantAppSettingsServiceModel} from "./models/tenant-app-settings-service.model";

@Injectable({
  providedIn: 'root'
})
export class TenantAppSettingsDataService {
  private readonly tenantEndpoint: string;
  private readonly systemEndpoint: string;
  private readonly appSettingsEndpoint: string;
  private readonly api: string;

  constructor(
    private _http: HttpClient,
    _config: ConfigurationService
  ) {
    let config = _config.configuration;
    this.tenantEndpoint = config.api.endpoints.tenant;
    this.systemEndpoint = config.api.endpoints.system;
    this.appSettingsEndpoint = config.api.endpoints.appSettings;
    this.api = config.api.backendUrl;
  }

  createAppSettings(systemId: number, tenantId: number, tenantAppSettings: TenantAppSettingsServiceModel): Observable<TenantAppSettingsServiceModel> {
    return this._http.post<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.appSettingsEndpoint}`, tenantAppSettings).pipe(
      map((tenantServiceModel: TenantServiceModel) => this.getTenantAppSettingsByTenantAppSettings(tenantServiceModel, tenantAppSettings))
    );
  }

  getAppSettings(systemId: number, tenantId: number, tenantAppSettingsId: number): Observable<TenantAppSettingsServiceModel> {
    return this._http.get<TenantAppSettingsServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.appSettingsEndpoint}/${tenantAppSettingsId}`);
  }

  deleteAppSettings(systemId: number, tenantId: number, tenantAppSettingsId: number): Observable<TenantServiceModel> {
    return this._http.delete<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.appSettingsEndpoint}/${tenantAppSettingsId}`);
  }

  updateAppSettings(systemId: number, tenantId: number, tenantAppSettings: TenantAppSettingsServiceModel): Observable<TenantAppSettingsServiceModel> {
    return this._http.put<TenantServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.tenantEndpoint}/${tenantId}/${this.appSettingsEndpoint}/${tenantAppSettings.id}`, tenantAppSettings).pipe(
      map((tenantServiceModel: TenantServiceModel) => this.getTenantAppSettingsById(tenantServiceModel, tenantAppSettings.id))
    );
  }

  private getTenantAppSettingsByTenantAppSettings(tenantServiceModel: TenantServiceModel, appSettings: TenantAppSettingsServiceModel): TenantAppSettingsServiceModel {
    const updatedTenant = tenantServiceModel.appSettings.find(
      tenant =>
        (tenant.appName === appSettings.appName) &&
        (tenant.description === appSettings.description)
      );
    return updatedTenant || {} as TenantAppSettingsServiceModel;
  }

  private getTenantAppSettingsById(tenantServiceModel: TenantServiceModel, tenantAppSettingsId: number | null): TenantAppSettingsServiceModel {
    return tenantServiceModel.appSettings.find(tenantAppSettings => tenantAppSettings.id === tenantAppSettingsId) || {} as TenantAppSettingsServiceModel;
  }
}
