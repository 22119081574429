import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import moment from 'moment';

export class TenantTokenDetailModel implements LipoModelInterface {
  constructor(
    public name: string = '',
    public token: string = '',
    public validUntil: moment.Moment | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

export class TenantTokenDetailSaveModel implements LipoModelInterface {
  constructor(
    public name: string = '',
    public validUntil: moment.Moment | null = null,
    public tokenLength: number = 32,
  ) {
  }

  getId(): null {
    return null;
  }
}
