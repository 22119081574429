import {Component, OnInit} from '@angular/core';
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {ProductDataService} from "../../services/product-data.service";
import {ProductServiceMapper} from "../../mappers/product-service.mapper";
import {ProductCardMapper} from "../../mappers/product-card.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {Router} from "@angular/router";
import {ProgressService} from "../../../shared/services/progress.service";

@Component({
  selector: 'du-product-overview',
  standalone: true,
    imports: [
        LipoOverviewComponent
    ],
  templateUrl: './product-overview.component.html',
  styleUrl: './product-overview.component.scss'
})
export class ProductOverviewComponent implements OnInit {
  productOverviewModel?: LipoOverviewModel

  constructor(
    private _productService: ProductDataService,
    protected router: Router,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._productService.getProducts().subscribe({
      next: products => {
        let productsOverview = products.map(ProductServiceMapper.toProductOverviewMapper)
        let productCards = productsOverview.map(ProductCardMapper.toLipoCardModel)

        this.productOverviewModel = {
          title: 'products',
          buttons: LipoButton.build({
            text: "button.product.add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.PRODUCT, 'new'])
          }),
          cards: productCards
        }
      },
      complete: () => this._progressService.stopLoading()
    })
  }
}
