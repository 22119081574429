import {LipoFormControlModel} from "./lipo-form-control.model";

export class LipoFormGroupModel {
  key: string;
  label: string;
  order: number;
  controls: LipoFormControlModel[];
  style: string;

  constructor(options: {
    key?: string;
    label?: string;
    order?: number;
    controls?: LipoFormControlModel[];
    style?: string;
  } = {}) {
    this.key = options.key ?? '';
    this.label = options.label ?? '';
    this.order = options.order ?? 1;
    this.controls = options.controls ?? [];
    this.style = options.style ?? '';
  }
}
