import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Provider} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ConfigurationService} from "./shared/services/configuration.service";
import {errorResponseInterceptor} from "./shared/interceptors/error-response.interceptor";
import {firstValueFrom} from "rxjs";
import {DatePipe} from "@angular/common";
import {MatDateFormats} from "@angular/material/core";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";

const initializeKeycloak = (keycloak: KeycloakService, config: ConfigurationService) => {
  return async () => {
    // load the configs for the keycloak config
    await firstValueFrom(config.loadConfigs())
    return keycloak.init({
      config: {
        url: config.configuration.keycloak.issuer,
        realm: config.getRealm(),
        clientId: config.configuration.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        redirectUri: `${window.location.origin}`
      },
      bearerPrefix: 'Bearer',
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true,
    })
  };
}


const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService, ConfigurationService]
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([errorResponseInterceptor])),
    provideRouter(routes),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    provideAnimations(),
    provideAnimationsAsync(),
    KeycloakService,
    importProvidersFrom(TranslateModule.forRoot(provideTranslation())),
    DatePipe,
    provideMomentDateAdapter(DATE_FORMATS)
  ]
};
