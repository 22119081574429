import {Component, Input} from '@angular/core';
import {LicenseOverviewModel} from "../models/license-overview.model";
import {TranslateModule} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'du-license-overview-card-body',
  standalone: true,
  imports: [
    TranslateModule,
    DatePipe
  ],
  templateUrl: './license-overview-card-body.component.html',
  styleUrl: './license-overview-card-body.component.scss'
})
export class LicenseOverviewCardBodyComponent {
  @Input() licenseOverviewModel?: LicenseOverviewModel;
}
