import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoModelInterface} from "../../interfaces/lipo-model.interface";
import {PipeTransform} from "@angular/core";
import {LipoButton} from "./lipo-button";

export class LipoTableModel<T extends LipoModelInterface> {
  constructor(
    public tableDataSource: MatTableDataSource<T>,
    public selectionModel: SelectionModel<T>,
    public displayedColumns: LipoTableDisplayedColumns[],
    public buttons?: LipoButton[],
    public onRowClick?: (value: LipoModelInterface) => Promise<any>
  ) {
  }
}

export class LipoTableDisplayedColumns {
  public HeaderCellName!: string;
  public PropertyName!: string;
  public Pipes: PipeTransform[] = [];

  static build(...columns: {HeaderCellName: string, PropertyName: string, Pipes?: PipeTransform[]}[]): LipoTableDisplayedColumns[] {
    return columns.map(item => {
      const instance = new LipoTableDisplayedColumns();
      instance.HeaderCellName = item.HeaderCellName;
      instance.PropertyName = item.PropertyName;
      instance.Pipes = item.Pipes || [];
      return instance;
    });
  }
}
