@if(_keycloakService.isLoggedIn()) {
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav" mode="side" opened [fixedInViewport]="true" [disableClose]="true" [style.max-width.px]="250">
      <du-sidenav/>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
