import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {SystemOverviewModel} from "../services/models/system-overview.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class SystemOverviewMapper {
  public static toLipoCard(systemOverviewModel: SystemOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: systemOverviewModel.id,
      route: LipoRouteEnum.SYSTEM,
      title: systemOverviewModel.name,
      logoUrl: systemOverviewModel.logo,
      tenantsAmount: systemOverviewModel.tenants.length
    });
  }
}
