import {Injectable, signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private readonly _isLoading: WritableSignal<boolean> = signal(false);
  private readonly _delay: number = 500;
  private _loadingTimeout: any;

  constructor() {
  }

  startLoading(): void {
    this._loadingTimeout = setTimeout(() => {
      this._isLoading.set(true);
    }, this._delay);
  }

  stopLoading(): void {
    clearTimeout(this._loadingTimeout);
    this._isLoading.set(false);
  }

  get isLoading() {
    return this._isLoading.asReadonly();
  }
}
